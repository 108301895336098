import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react';
import Layout from '../../components/layout'
import WorkCard from '../../components/workcard';
import { BgImage } from "gbimage-bridge"


const WorkPost = ({ data }) => {

    return (
        <Layout pageTitle={data.mdx.frontmatter.title} pageDescription={data.mdx.frontmatter.headline} pageImage={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData.images.fallback.src} pageArticle={true}>



            <BgImage Tag="section" data-jarallax data-speed=".8" className="py-12 py-md-15 bg-cover jarallax" image={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData} alt={data.mdx.frontmatter.hero_image_alt}>
            </BgImage>




            <section class="pt-8 pt-md-8">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-lg-9 col-xl-8">

                            <h4 class="display-6 fw-light text-uppercase text-muted text-center">
                                {data.mdx.frontmatter.subtitle}
                            </h4>

                            <h1 class="display-3 fw-bold text-center">
                                {data.mdx.frontmatter.title}
                            </h1>

                            <p class="lead mb-7 text-center text-muted">
                                {data.mdx.frontmatter.headline}
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <hr class="hr-md mb-1 mx-auto" />

            <section class="pt-6 pt-md-8">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-lg-9 col-xl-8">


                            <MDXProvider>
                                <MDXRenderer slideshow_images={data.mdx.frontmatter.slideshow_images}>
                                    {data.mdx.body}
                                </MDXRenderer>
                            </MDXProvider>


                        </div>
                    </div>
                </div>
            </section>







            <div class="position-relative pt-10">
                <div class="shape shape-bottom shape-fluid-x text-light">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" /></svg>      </div>
            </div>


            <section class="pt-7 pt-md-10 pb-7 pb-md-10 bg-light">
                <div class="container">
                    <div class="row align-items-center mb-5">
                        <div class="col-12 col-md">
                            <h3 class="mb-0">
                                Other Work
                            </h3>
                            <p class="mb-0 text-muted">
                                Explore other projects that I've worked on.
                            </p>
                        </div>
                        <div class="col-12 col-md-auto">
                            <Link to="/work/" className="btn btn-sm btn-outline-gray-300 d-none d-md-inline">View all</Link>
                        </div>
                    </div>
                    <div class="row">

                        {
                            data.allMdx.nodes.map((node) => (

                                <WorkCard data={node} />

                            ))
                        }

                    </div>
                </div>
            </section>





        </Layout>
    )
}






export const query = graphql`
query ($slug: String) {
  mdx(slug: {eq: $slug}) {
    body
    frontmatter {
      title
      subtitle
      headline
      date(formatString: "MMMM D, YYYY")
      displayorder
      worktypes
      slideshow_images {
          childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  width: 800
                  )
          }
      }
      hero_image_alt
      hero_image {
        childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
  allMdx(sort: {fields: frontmatter___displayorder, order: ASC}, limit: 3, filter: {slug: {ne: $slug}, frontmatter: {published: { eq: true }}}) {
    nodes {
      frontmatter {
        title
        subtitle
        worktypes
        cover_image {
          childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
                width: 400
                )
          }
        }
        cover_image_alt
      }
      id
      slug
    }
  }
}
`


export default WorkPost