import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'




const WorkCard = ({ data }) => {
    const cover_image = getImage(data.frontmatter.cover_image)

    return (

        <div className={`col-12 col-md-4 ${data.frontmatter.worktypes}`} key={data.slug}>

            <Link to={`/work/${data.slug}/`} className="card shadow-light-lg mb-7">

                <div className="card-zoom">
                    <GatsbyImage image={cover_image} alt={data.frontmatter.cover_image_alt} className="card-img-top" />
                </div>

                <div className="card-body bg-white">
                    <div className="shape shape-bottom-100 shape-fluid-x text-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" /></svg>
                    </div>

                    <h6 className="text-uppercase mb-1 text-muted">{data.frontmatter.subtitle}</h6>
                    <h4 className="mb-0">{data.frontmatter.title}</h4>
                </div>

            </Link>

        </div>

    )
}
export default WorkCard
